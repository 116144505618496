body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.places-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 50;
  width: 300px;
  transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);

}

.places-getLocation {
  position: absolute;
  bottom: 10px;
  left: 80px;
  transform: translateX(-50%);

  padding: .5em;
}

.places-container-menu-open {
  left: 175px;
  transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);

}

.places-sidebar {
  position: absolute;
  z-index: 5;
  height: 100vh;
  width: 375px;
  left: 0px;
  background-color: white;
  transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
}

.slide-bar {
  left: -500px;
  transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
}

.menu-close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}

.default-image:hover + .image-number {
  display: flex;
}

.default-image:hover {
  cursor: pointer;
}

.image-number {
  display: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 200px;
  left: 30px;
  width: 120px;
  height: 35px;
  background-color: black;
  color: white;
  text-align: center;
}

.data-details {
  padding: 0 2em;
}

.data-options {
  display: flex;
  justify-content: space-around;
}

#infoWindow {
  background-color: white;
  opacity: 1;

}
